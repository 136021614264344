import { autoUpdate, Placement, useFloating } from '@floating-ui/react';
import { isNil, xor } from 'lodash-es';
import { useEffect, useState } from 'react';

import { useSearch } from '@hofy/hooks';

import { useIsDisabled } from '../../../contexts';
import { InteractiveMultiList } from '../types/InteractiveListTypes';
import { useA11ySearchListInteractions } from './a11y/useA11ySearchListInteractions';
import { dropdownMiddleware } from './dropdownMiddleware';

export const useInteractiveMultiListSearch = <T>({
    values,
    onChange,
    options,
    toText,
    toLabel,
    toKey,
    disabled: listDisabled,
    contentWidth,
    contentMaxHeight,
    placement: initialPlacement = 'bottom-start',
}: InteractiveMultiList<T>) => {
    const disabled = useIsDisabled(listDisabled);

    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [placement, setPlacement] = useState<Placement | null>(null);

    const {
        refs,
        floatingStyles,
        context,
        placement: resultantPlacement,
    } = useFloating({
        placement: placement ?? initialPlacement, // Keep original placement while searching to avoid jumping around
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: dropdownMiddleware({ contentWidth, contentMaxHeight }),
        whileElementsMounted: autoUpdate,
    });

    const select = (selectedValue: T) => {
        onChange(xor(values, [selectedValue]));
    };

    const selectByIndex = (index: number) => {
        const item = searchResults ? searchResults[index] : options[index];
        select(item);
    };

    const { referenceProps, floatingProps, inputProps, itemProps, activeIndex, listRef, setActiveIndex } =
        useA11ySearchListInteractions({
            context,
            disabled,
            onChange: select,
            onSearchEnter: selectByIndex,
        });

    useEffect(() => {
        if (isOpen) {
            setPlacement(resultantPlacement);
        } else {
            setSearch('');
            setActiveIndex(null);
            setPlacement(null);
        }
    }, [isOpen, resultantPlacement]);

    const results = useSearch(options, toText, search);
    const searchResults = search ? results : undefined;

    const getLabel = (value: T) => {
        if (isNil(value)) {
            return null;
        }
        const text = toText(value!);
        if (toLabel) {
            return toLabel(value!);
        }
        return text;
    };

    const getKey = (value: T) => {
        if (toKey) {
            return toKey(value!);
        }
        return toText(value!);
    };

    const handleSearch = (value: string) => {
        setActiveIndex(null);
        setSearch(value);
    };

    return {
        searchResults,
        search,
        handleSearch,

        refs,
        context,
        isOpen,
        setIsOpen,
        activeIndex,
        getLabel,
        getKey,
        values,
        referenceProps,
        inputProps,
        itemProps,
        floatingProps,
        listRef,
        floatingStyles,
        disabled,
        resultantPlacement,
    };
};
