import React, { FC } from 'react';

import { useOrganizationContract } from '@hofy/api-admin';
import { Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, PageHeader, Skeleton, Tab, Tabs } from '@hofy/ui';

import {
    allOrganizationContractTabs,
    OrganizationContractTab,
} from '../../../store/contracts/types/OrganizationContractTab';
import { useTrOrganizationContractTab } from '../../../store/organizationContracts/useTrOrganizationContractTab';
import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { OrganizationContractDetails } from './components/OrganizationContractDetails';
import { SeatAssignmentTable } from './components/SeatAssignmentTable';
import { SubscriptionTable } from './components/SubscriptionTable';

interface OrganizationContractDetailsOverlayProps {
    organizationId: UUID;
    organizationContractId: UUID;
    organizationContractTab: OrganizationContractTab;
    onChangeTab(tab: OrganizationContractTab): void;
}

export const OrganizationContractDetailsOverlay: FC<OrganizationContractDetailsOverlayProps> = ({
    organizationId,
    organizationContractId,
    organizationContractTab,
    onChangeTab,
}) => {
    const trTab = useTrOrganizationContractTab();
    const { data: organization, isLoading: isLoadingOrganization } =
        useOrganizationDetailsQuery(organizationId);
    const { organizationContract, isLoading: isLoadingContract } = useOrganizationContract(
        organizationId,
        organizationContractId,
    );

    if (isLoadingOrganization || isLoadingContract) {
        return (
            <Overlay column flex='auto' bg={Color.BackgroundDefault}>
                <Skeleton />
            </Overlay>
        );
    }

    const renderContent = () => {
        switch (organizationContractTab) {
            case OrganizationContractTab.Details:
                return <OrganizationContractDetails organizationContract={organizationContract!} />;
            case OrganizationContractTab.Seats:
                return <SeatAssignmentTable seatAssignments={organizationContract!.seats} />;
            case OrganizationContractTab.Subscriptions:
                return <SubscriptionTable subscriptions={organizationContract!.subscriptions} />;
        }
    };

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                title={organization!.name}
                tabsSlot={
                    <Tabs active={organizationContractTab} onChange={onChangeTab}>
                        {allOrganizationContractTabs.map(tab => (
                            <Tab key={tab} id={tab} label={trTab(tab)} />
                        ))}
                    </Tabs>
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>
        </Overlay>
    );
};
