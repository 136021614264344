import { autoUpdate, Placement, useFloating } from '@floating-ui/react';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';

import { useSearch } from '@hofy/hooks';

import { useIsDisabled } from '../../../contexts';
import { InteractiveList } from '../types/InteractiveListTypes';
import { useA11ySearchListInteractions } from './a11y/useA11ySearchListInteractions';
import { dropdownMiddleware } from './dropdownMiddleware';

export const useInteractiveListSearch = <T>({
    value,
    onChange,
    options,
    toText,
    toLabel,
    toSelectedLabel,
    toKey,
    disabled: listDisabled,
    contentWidth,
    contentMaxHeight,
    nullable,
    onOpenChange,
    placement: initialPlacement = 'bottom-start',
}: InteractiveList<T>) => {
    const disabled = useIsDisabled(listDisabled);

    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [placement, setPlacement] = useState<Placement | null>(null);

    const selectedIndex = value === null ? -1 : options.indexOf(value!);
    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
        onOpenChange?.(open);
    };
    const {
        refs,
        floatingStyles,
        context,
        placement: resultantPlacement,
    } = useFloating({
        placement: placement ?? initialPlacement, // Keep original placement while searching to avoid jumping around
        open: isOpen,
        onOpenChange: handleOpenChange,
        middleware: dropdownMiddleware({ contentWidth, contentMaxHeight }),
        whileElementsMounted: autoUpdate,
    });
    const select = (value: T) => {
        handleOpenChange(false);
        onChange(value);
    };

    const selectByIndex = (index: number) => {
        const item = searchResults ? searchResults[index] : options[index];
        select(item);
    };

    const { referenceProps, floatingProps, inputProps, itemProps, activeIndex, listRef, setActiveIndex } =
        useA11ySearchListInteractions({
            context,
            disabled,
            onChange: select,
            onSearchEnter: selectByIndex,
        });

    useEffect(() => {
        if (isOpen) {
            setPlacement(resultantPlacement);
        } else {
            setSearch('');
            setActiveIndex(null);
            setPlacement(null);
        }
    }, [isOpen, resultantPlacement]);

    const results = useSearch(options, toText, search);
    const searchResults = search ? results : undefined;

    const clear = () => {
        if (nullable) {
            onChange(null);
        }
    };

    const getLabel = (value: T) => {
        if (isNil(value)) {
            return null;
        }
        const text = toText(value!);
        if (toLabel) {
            return toLabel(value!);
        }
        return text;
    };

    const getSelectedLabel = (value: T) => {
        if (toSelectedLabel) {
            return toSelectedLabel(value);
        }
        if (isNil(value)) {
            return null;
        }
        return getLabel(value);
    };

    const getKey = (value: T) => {
        if (toKey) {
            return toKey(value!);
        }
        return toText(value!);
    };

    const handleSearch = (value: string) => {
        setActiveIndex(null);
        setSearch(value);
    };

    return {
        searchResults,
        search,
        handleSearch,

        refs,
        context,
        isOpen,
        setIsOpen,
        activeIndex,
        selectedIndex,
        getLabel,
        getSelectedLabel,
        getKey,
        clear,
        value,
        referenceProps,
        inputProps,
        itemProps,
        floatingProps,
        listRef,
        floatingStyles,
        disabled,
        resultantPlacement,
    };
};
